import 'ant-design-vue/dist/antd.css'
import '@/global.scss' // global style
import '@/assets/imgs'
import { createApp, h } from 'vue'
import Antd, { message } from 'ant-design-vue'
import router from './router'
import SvgIcon from '@/components/SvgIcon'
import App from './App'
import { notification, Button } from 'ant-design-vue';
import { createWebSocket } from '@/utils/websocket'; //WebSocket封装方法
import { InfoCircleOutlined,  } from "@ant-design/icons-vue";
import axios from 'axios'

const app = createApp(App)

// 获取当前的域名
// const hostname = window.location.hostname;
// if(hostname == "localhost" || hostname == "127.0.0.1") {
//     axios.defaults.baseURL = '/api'
// }else{
//     axios.defaults.baseURL = "https://api-admin.zhuake.com/";
// }
axios.defaults.baseURL = '/api'

// axios请求拦截
axios.interceptors.request.use((config) => {
    // 为当前请求配置 Token 认证字段
        const token = sessionStorage.getItem('token')
        if (token) {
            config.headers.Authorization = 'Bearer ' + token
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// axios响应拦截
axios.interceptors.response.use(
    (response) => {
        // 检查响应头中是否包含 refresh-token，并更新本地 Token
        const refreshToken = response.headers['refresh-token'];
        if (refreshToken) {
            sessionStorage.setItem('token', refreshToken);
        }
        return response;
    },
    (error) => {
        // 检查是否为未认证错误，如果是则提示用户重新登录
        if (error.response && error.response.status === 401) {
            message.error('认证失败，请重新登录！');
            router.push('/login');
        }
        return Promise.reject(error);
    }
);


// 动态注册路由
const menus = JSON.parse(sessionStorage.getItem('menus'))
if (menus) {
    const filterAsyncRouter = (asyncRouterMap) => { // 遍历后台传来的路由字符串，转换为组件对象
        const accessedRouters = asyncRouterMap.filter(route => {
            if (route.component) {
                const component = route.component
                route.component = () => import('@/views/' + component + '.vue')
            }

            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children)
            }
            return true
        })

        return accessedRouters
    }

    // const getRouter = filterAsyncRouter(menus)
    // getRouter.forEach((route) => {
    //     router.addRoute('layout', route)
    // })

    // 设置全局变量
    app.config.globalProperties.$userRouter = filterAsyncRouter(menus)
    app.config.globalProperties.$userRouter.forEach((route) => {
        router.addRoute('home', route)
    })

    // 创建websocket链接
    const url = "wss://api-admin.zhuake.com/ws?room_id=ydd";
    app.config.globalProperties.$ws = createWebSocket(url, function(ws, data) {
        const jsoned = /\{(.*)\}/g.test(data);

        // 客户端配置
        const clientConfig = JSON.parse(localStorage.getItem('clientConfig')) || {}

        // 下单成功通知提示音
        const orderNotificationMusic = clientConfig.orderNotificationMusic || '001'

        const mp3 = require('@/assets/' + orderNotificationMusic + '.mp3')

        // $buttonAudio全局变量
        const buttonAudio = new Audio(mp3);
        const autoOrderTaking = clientConfig.autoOrderTaking
        const autoPrint = clientConfig.autoPrint
        var printer = null

        // 下单自动提醒
        if (jsoned && autoOrderTaking) {
            const orderInfo = JSON.parse(data)

            // 如果音效暂停中，则播放
            if (buttonAudio.paused) {
            buttonAudio.play()
            }

            // 弹出框消息
            const key = `open${Date.now()}`;
            notification.open({
            message: () => h(<span>通知</span>, {
                style: 'color: #108ee9',
            }),
            description: `您有新的订单《${orderInfo.order_sn}》。`,
            icon: () => h(InfoCircleOutlined, {
                style: 'color: #108ee9',
            }),
            btn: () => h(Button, {
                type: 'primary',
                size: 'small',
                onClick: () => {
                notification.close(key)
                console.log(999999)
                },
            }, {
                default: () => '查看',
            }),
            key,
            onClose: () => {
                // const data = {'text':12, 'order_sn': '222'}
                // ws.send(JSON.stringify(data))
            },
            // style: {
            //   width: '600px',
            //   marginLeft: `${335 - 600}px`,
            // },
            // class: 'notification-custom-class',
            });

            // console.log('消息确定')
            // const msg = `{'state': 'ok', 'order_sn': ${orderInfo.order_sn}}`
            // orderInfo.send_state = 1
            // console.log('orderInfo', orderInfo)
            // ws.send(JSON.stringify(orderInfo));
        }

        // 下单自动打印
        if (jsoned && autoPrint) {
            if (printer) {
                printer.send(data)
            } else {
                try {
                    printer = new WebSocket(clientConfig.printer_client_ws);
                    printer.onopen = function (event) {
                        console.log("Printer connected successfully.");

                        // 在连接建立后发送数据
                        printer.send(data);
                    };
                } catch (error) {
                    console.log("printer", error)
                }
            }
        }

        //
        // app.config.globalProperties.$ws.send(JSON.stringify({'state':'ok'}));
    }, null)
}

app.component('svg-icon', SvgIcon)
app.use(Antd).use(router).mount('#app')