<template>
  <a-modal style="width: 300px" v-model:visible="themeWinState" title="主题切换" okText="保存设置" @ok="themeHandleOk">
    <a-form-item label="当前主题">
      <a-select v-model:value="theme" placeholder="请选择主题">
        <a-select-option value="light">明亮</a-select-option>
        <a-select-option value="dark">黑暗</a-select-option>
      </a-select>
    </a-form-item>  
  </a-modal>
  <a-layout :has-sider="hasSider" class="layout">
    <!--左边导航栏-->
    <a-layout-sider :theme="theme" v-model:collapsed="collapsed" :trigger="null" style="font-size: 12px; z-index: 999;">
      <!-- Logo -->
      <div class="logo">
        <svg-icon style="width: 32px; height: 32px; vertical-align: middle" icon-class="logo"></svg-icon>
        <h1>抓客·点单</h1>
        <!-- <h1>悠点单</h1> -->
      </div>

      <!-- 左侧菜单 -->
      <a-menu mode="inline" :theme="theme" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys"
        @click="createTabs">
        <template v-for="item in menuList" :key="item.name">
          <template v-if="item.children.length > 0">
            <sub-menu :key="item.name" :menu-info="item" />
          </template>
          <template v-else>
            <a-menu-item :title="item.meta.title" :key="item.name">
              <template #icon>
                <component :is="item.meta.icon" />
              </template>
              {{ item.meta.title }}
            </a-menu-item>
          </template>
        </template>
      </a-menu>

    <!-- 左侧底部 -->
    <!-- <div class="user-info-container">
      <a-card 
        v-if="!collapsed"
        :bodyStyle="{ color: theme == 'dark' ? 'rgba(225, 225, 225, 0.36)' : 'rgba(0, 0, 0, 0.36)', fontSize: '10px' }"  
        style="border-radius:0; background-color: transparent; width: 100%;"
        size="small"
        :bordered="false"
      >
        <p>商户ID：{{ avatarParams.currentUser.mid }}</p>
        <p>有效期：{{ avatarParams.currentUser.expiry_time }}</p>
      </a-card>
    </div> -->

    </a-layout-sider>
    <a-layout style="background: #fff">
      <a-layout-header style="padding: 0; background: #fff; height: auto">
        <a-row class="header" type="flex" :gutter="[0, 0]" justify="start">
          <a-col :flex="8">
            <menu-unfold-outlined v-if="collapsed" style="font-size: 18px; vertical-align: middle" class="trigger"
              @click="() => (collapsed = !collapsed)" />
            <menu-fold-outlined v-else style="font-size: 18px; vertical-align: middle" class="trigger"
              @click="() => (collapsed = !collapsed)" />
            <a-menu theme="light" mode="horizontal" style="line-height: 64px; background: #fff; display: inline-block; width: 80%;">
              <template :key="item.key" v-for="item in quickAccessData">
                <a-dropdown :trigger="['contextmenu']">
                  <a-menu-item @click="quickAccessClick(item)">{{ item.title }}</a-menu-item>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item :title="item.title" :keyPath="item.key" key="delete"><DeleteOutlined /> 删除</a-menu-item>
                      <a-menu-item :title="item.title" :keyPath="item.key" key="clear"><ClearOutlined /> 清空</a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </a-menu>

            <!-- <div class="header-right">
              <notice-icon style="padding: 0 18px" />
              <avatar-dropdown :menu="showMenu" :current-user="currentUser" />
            </div> -->
          </a-col>
          <a-col flex="auto">
            <div class="header-right">
              <!-- <span class="wecaht">
                <wechat-outlined />
              </span> -->
              
              <notice-icon style="padding: 0 18px" />
              <avatar-dropdown :params="avatarParams" @themeChange="themeChange" />
            </div>
          </a-col>
        </a-row>

        <a-row type="flex" :gutter="[0, 0]" justify="start">
          <a-col :flex="1">
            <!--面包屑-->
            <a-breadcrumb v-if="breadList" style="padding: 20px 20px; background: rgba(0, 0, 0, 0.015)">
              <template :key="i" v-for="(bread, i) in breadList">
                <a-breadcrumb-item v-if="bread.meta.title">
                  <!-- <component :is="bread.meta.icon" /> -->
                  <template v-if="i + 1 === breadList.length || $router.currentRoute.value.fullPath === '/home'">
                    <span>{{ bread.meta.title }}</span>
                  </template>
                  <template v-else>
                    <span style="cursor: pointer" @click="tabChange(bread.redirect ?? bread.path)">{{ bread.meta.title
                    }}</span>
                  </template>
                </a-breadcrumb-item>
              </template>
            </a-breadcrumb>
            <!--tabs页签-->
            <div style="
                height: 40px;
                line-height: 40px;
                padding: 0px 20px;
                background: rgba(0, 0, 0, 0.015);
                border-bottom: #f0f0f0 1px solid;
              ">
              <a-tabs v-model:activeKey="activeKey" type="editable-card" :tabBarGutter="10" :animated="false"
                :hideAdd="true" @edit="onEdit" @change="tabChange">
                <a-tab-pane style="font-size: 12px" :tab="renderTabPane(pane.title, pane.key)" :key="pane.key"
                  :id="pane.key" :closable="pane.closable" v-for="pane in panes" />
              </a-tabs>
            </div>
          </a-col>
        </a-row>

        <!-- <div class="header-left">
          <menu-unfold-outlined
            v-if="collapsed"
            style="font-size: 18px; vertical-align: middle"
            class="trigger"
            @click="() => (collapsed = !collapsed)"
          />
          <menu-fold-outlined
            v-else
            style="font-size: 18px; vertical-align: middle"
            class="trigger"
            @click="() => (collapsed = !collapsed)"
          />
          <a-menu
            v-model:selectedKeys="selectedKeys"
            theme="light"
            mode="horizontal"
            :style="{
              lineHeight: '64px',
              background: '#fff',
              display: 'inline-block',
            }"
          >
            <a-menu-item key="1">网站设置</a-menu-item>
            <a-menu-item key="2">协同工具</a-menu-item>
            <a-menu-item key="3">营销工具</a-menu-item>
          </a-menu>
        </div>
        <div class="header-right">
          <notice-icon style="padding: 0 18px" />
          <avatar-dropdown :menu="showMenu" :current-user="currentUser" />
        </div> -->
      </a-layout-header>
      <a-layout-content :style="{
        //margin: '24px 16px',
        // padding: '24px',
        // minHeight: '280px',
        background: '#fff',
        height: '100%',
        // overflow: 'hidden',
        'overflow-x': 'hidden',
        'overflow-y': 'auto',
      }">
        <!-- 路由视图 -->
        <div style="margin-top: 10px; padding: 10px 20px">
          <router-view v-if="isRefresh" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.path" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" :key="$route.path" v-if="!$route.meta.keepAlive" />
          </router-view>
        </div>
      </a-layout-content>
      <!-- <a-layout-footer :style="{ textAlign: 'center' }">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script>
import * as icons from "@ant-design/icons-vue";
import { ref, getCurrentInstance, nextTick, defineComponent } from "vue";
import AvatarDropdown from "@/components/GlobalHeader/AvatarDropdown";
import router from "@/router";
// import { getLodop } from "@/utils/LodopFuncs"
import NoticeIcon from "@/components/NoticeIcon";
import SubMenu from "@/components/SubMenu.vue";


export default defineComponent({
  components: {
    ...icons,
    SubMenu,
    NoticeIcon,
    AvatarDropdown
  },
  setup() {
    // 主题窗口状态
    const themeWinState = ref(false);

    // 快捷访问数据
    const quickAccessData = ref();

    // 从 localStorage 中读取值，并反序列化为 ref 变量
    quickAccessData.value = JSON.parse(localStorage.getItem('quickAccessData')) || [];

    // 路由守卫，切换改变面包屑
    router.afterEach(() => {
      changBread();
    });

    // 局部刷新
    const isRefresh = ref(true);
    const onRefresh = () => {
      isRefresh.value = false;
      nextTick(() => {
        isRefresh.value = true;
      });
    };

    // 同步左侧菜单
    const syncMenu = (uri)=>{ 
      const paths = uri.replace("/", "").split("/");

      // 同步左侧栏，处理多级菜单如：/user/table/analyse
      openKeys.value = []
      const len = paths.length - 1
      for (let i = 0; i < len; i++) {
        openKeys.value.push(paths[i])
      }

      // 选中的SubMenu选项，如：analyse
      selectedKeys.value = [paths[len]];
    }

    // 关闭标签
    const closeCurrent = (keyPath) => {
      remove(keyPath);
    };

    const renderTabPane = (title, keyPath) => {
      const isHome = keyPath === "/home";
      const isActive = keyPath === activeKey.value;
      const isQuickAccess = quickAccessData.value.some(item => item.key === keyPath);
      const text = isQuickAccess ? '删除快访' : '添加快访'
      const menu = (
        <a-menu>
          <a-menu-item
            disabled={isHome || !isActive}
            onclick={() => {
              if (!isHome && isActive) {
                if(isQuickAccess) {
                  delQuickAccess(title, keyPath);
                } else {
                  addQuickAccess(title, keyPath);
                }
              }
            }}
            key="quickAccess"
          >
           {text}
          </a-menu-item>
          <a-menu-item
            disabled={isHome}
            onclick={() => {
              if (!isHome) {
                closeCurrent(keyPath);
              }
            }}
            key="closeCurrent"
          >
            关闭标签
          </a-menu-item>
          <a-menu-item
            disabled={!isActive}
            onclick={() => {
              if (isActive) {
                onRefresh();
              }
            }}
            key="closeLeft"
          >
            刷新标签
          </a-menu-item>
          <a-menu-item
            disabled={isHome}
            onclick={() => {
              if (!isHome) {
                closeAll();
              }
            }}
            key="closeAll"
          >
            关闭全部
          </a-menu-item>
        </a-menu>
      );

      return (
        <a-dropdown overlay={menu} trigger={["contextmenu"]}>
          <span style={{ userSelect: "none" }}>{title}</span>
        </a-dropdown>
      );
    };

    // 动态注册路由
    const userRouter = getCurrentInstance().appContext.config.globalProperties.$userRouter;
    const menus = JSON.parse(sessionStorage.getItem("menus"));
    if (menus && !userRouter) {
      const filterAsyncRouter = (asyncRouterMap) => {
        // 遍历后台传来的路由字符串，转换为组件对象
        const accessedRouters = asyncRouterMap.filter((route) => {
          if (route.component) {
            const component = route.component;
            route.component = () => import("@/views/" + component + ".vue");
          }

          if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children);
          }
          return true;
        });

        return accessedRouters;
      };

      const getRouter = filterAsyncRouter(menus);
      getRouter.forEach((route) => {
        router.addRoute("home", route);
      });
    }

    const hasSider = ref(true);

    const theme = ref(localStorage.getItem('theme') || 'dark'); // light,dark

    // 打开的页签
    const panes = ref([]);
    const activeKey = ref();

    // 选中的菜单栏
    const openKeys = ref([]);
    const selectedKeys = ref([]);

    // 获取当前路由
    const curRoute = router.currentRoute.value;

    const menuList = JSON.parse(sessionStorage.getItem("menus"));

    // 面包屑列表
    const breadList = ref(null);

    // 非首页则认为默认打开路由
    if (curRoute.path !== "/") {
      let hasTab = false;
      const pathArr = curRoute.path.replace("/", "").split("/");

      // 展开菜单
      const len = pathArr.length - 1
      for (let i = 0; i < len; i++) {
        openKeys.value.push(pathArr[i])
      }

      // 展开菜单
      selectedKeys.value = [pathArr[len]];

      // 激活tab
      activeKey.value = curRoute.path;

      // 面包屑
      breadList.value = router.currentRoute.value.matched;

      // 页签缓存
      const tabs = JSON.parse(sessionStorage.getItem("tabs"));
      if (tabs) {
        tabs.forEach((item) => {
          // 缓存是否存在
          if (item.key === activeKey.value) {
            hasTab = true;
          }
          panes.value.push(item);
        });

        // 如果缓存不存在tab，则打开新的
        {
          if (hasTab === false) {
            panes.value.push({
              title: curRoute.meta.title,
              key: activeKey.value,
            });
            sessionStorage.setItem("tabs", JSON.stringify(panes.value));
          }
        }
        activeKey.value = curRoute.path;
      } else {
        panes.value.push({
          title: curRoute.meta.title,
          key: curRoute.path,
          closable: false,
        });
        sessionStorage.setItem("tabs", JSON.stringify(panes.value));
      }
    }

    // 快捷访问跳转
    const quickAccessClick = (item)=>{
      // 同步左边菜单，只允许打开一个SubMenu
      syncMenu(item.key)

      var tabState = false;
      const title = item.title
      activeKey.value = item.key

      // 路由跳转
      router.push({ path: activeKey.value })/*.then(() => {
        // 更换面包屑
        changBread();
      });*/

      // 检查页标签是否打开
      for (const index in panes.value) {
        if (panes.value[index].key === activeKey.value) {
          tabState = true;
          break;
        }
      }

      // 如果没有打开则push
      if (tabState === false) {
        panes.value.push({
          title,
          key: activeKey.value,
        });
        sessionStorage.setItem("tabs", JSON.stringify(panes.value));
      }
    }

    const handleMenuClick = (e)=>{
      if(e.key === 'delete') {
        delQuickAccess(e.item.title, e.item.keyPath)
      }else if(e.key === 'clear'){
        quickAccessData.value = []
        localStorage.setItem('quickAccessData', JSON.stringify(quickAccessData.value));
      }
    }

    // 删除快捷访问
    const delQuickAccess = (title, keyPath)=>{
      quickAccessData.value = quickAccessData.value.filter(item => item.key !== keyPath);
      localStorage.setItem('quickAccessData', JSON.stringify(quickAccessData.value));
    }

    // 添加为快捷访问
    const addQuickAccess = (title, keyPath)=>{
      const newItem = {
        key: keyPath,
        title: title
      }

      // 检查是否已经存在相同的对象
      if (!quickAccessData.value.some(item => item.key === newItem.key)) {
        quickAccessData.value.push(newItem);
      }
      localStorage.setItem('quickAccessData', JSON.stringify(quickAccessData.value));
    }

    // 关闭全部
    const closeAll = ()=>{
      panes.value = [
        {
          title: "首页",
          key: "/home",
          closable: false,
        },
      ];

      // tabs页签设置为home
      activeKey.value = "/home";

      // 清空左边菜单
      openKeys.value = [];
      selectedKeys.value = [];

      // 路由跳转为首页
      router.push("/home");

      sessionStorage.setItem("tabs", JSON.stringify(panes.value));
    };

    // 删除tab标签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);

      if (panes.value.length === 0) {
        tabChange("/");
      } else {
        if (panes.value.length && activeKey.value === targetKey) {
          if (lastIndex >= 0) {
            activeKey.value = panes.value[lastIndex].key;
          } else {
            activeKey.value = panes.value[0].key;
          }
        }

        // 如果是最后一个页签，则不可关闭
        // if (panes.value.length === 1) {
        //  panes.value[0].closable = false;
        // }

        tabChange(activeKey.value);
      }
      sessionStorage.setItem("tabs", JSON.stringify(panes.value));
    };
    // 创建tab标签
    const createTabs = (menuInfo) => {

      let tabState = false;
      const title = menuInfo.item.title;
      activeKey.value = "/" + menuInfo.keyPath.join("/");

      // 同步左边菜单，只允许打开一个SubMenu
      syncMenu(activeKey.value)

      // 路由跳转
      router.push({ path: activeKey.value })/*.then(() => {
        // 更换面包屑
        changBread();
      });*/

      // 检查页标签是否打开
      for (const index in panes.value) {
        if (panes.value[index].key === activeKey.value) {
          tabState = true;
          break;
        }
      }

      // 如果没有打开则push
      if (tabState === false) {
        panes.value.push({
          title,
          key: activeKey.value,
        });
        sessionStorage.setItem("tabs", JSON.stringify(panes.value));
      }
    };

    // 变更面包屑
    const changBread = () => {
      const curRoute = router.currentRoute.value;

      if (curRoute.path === "/") {
        breadList.value = null;
      } else {
        breadList.value = curRoute.matched;
      }
    };

    // tab切换事件
    const tabChange = (key) => {
      // 同步左边菜单，只允许打开一个SubMenu
      syncMenu(key)

      // 设置当前tab
      activeKey.value = key;

      router.push({ path: key })/*.then(() => {
        // 变更面包屑
        changBread();
      });*/
    };

    // 新增和删除页签的回调，在 type="editable-card" 时有效
    const onEdit = (targetKey, action) => {
      if (action === "remove") {
        remove(targetKey);
      }
    };

    // 当前用户信息
    //const currentUser = JSON.parse(sessionStorage.getItem("users"));

    const avatarParams = {
      menu: true,
      currentUser: JSON.parse(sessionStorage.getItem("users"))
    }

    // 当前用户信息
    getCurrentInstance().appContext.config.globalProperties.$userInfo = JSON.parse(sessionStorage.getItem("users"));

    // 显示切换主题窗口
    const themeChange = (state)=>{
      themeWinState.value = state
    }

    // 主题保存按钮事件
    const themeHandleOk = ()=>{
      localStorage.setItem('theme', theme.value)
      themeWinState.value = false;
    }

    return {
      panes,
      activeKey,
      onEdit,
      createTabs,
      tabChange,
      breadList,
      menuList,
      theme,
      //currentUser,
      collapsed: ref(false), // 侧栏收起状态
      //showMenu: ref(true),
      openKeys,
      selectedKeys,
      hasSider,
      renderTabPane,
      isRefresh,
      onRefresh,
      quickAccessClick,
      quickAccessData,
      syncMenu,
      themeWinState,
      avatarParams,
      themeChange,
      themeHandleOk,
      handleMenuClick
    };
  }
});
</script>
<style lang="scss" scoped>
// 左侧底部
.user-info-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

// light风格logo标题样式
.ant-layout-sider-light > .ant-layout-sider-children {
  .logo {
    h1 {
      color: #1890ff;
    }
  }
}

// 修改快捷访问样式
.ant-menu-horizontal:not(.ant-menu-dark) {
  border-bottom: none;
  :deep(.ant-menu-item), 
  :deep(.ant-menu-item-selected) {
    color: inherit;
    &::after {
      border-bottom: none;
    }
  }
  
  :deep(.ant-menu-item) {
    &:hover {
      color: #1890ff;
    }
  }
}

// 左侧栏阴影
.ant-layout-sider {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}

.layout {
  height: 100vh;

  .header {
    position: relative;
    height: 64px;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(31, 34, 36, 0.08);
    -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }
}

.layout .trigger {
  // font-size: 18px;
  //height: 64px;
  //line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s;

  &:hover {
    c: #1890ff;
  }
}

.layout .sync {
  height: 64px;
  line-height: 64px;
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
}

.layout .logo {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #fff;
  // background: rgba(255, 255, 255, 0.3);
  margin: 16px;
  text-align: center;
  align-items: center;
  overflow: hidden;

  h1 {
    color: #fff;
    font-size: 18px;
    margin: 0 0 0 12px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    // font-weight: 400;
    font-weight: lighter;
    vertical-align: middle;
    display: inline-block;
  }
}

.header-left {
  margin-right: 18px;
  cursor: pointer;
  float: left;
  height: 100%;
  font-size: 12px;
  margin-left: auto;
  overflow: hidden;
}

.header-right {
  margin-right: 18px;
  cursor: pointer;
  float: right;
  height: 100%;
  font-size: 12px;
  margin-left: auto;
  overflow: hidden;
}

.wechat {
  display: inline-block;
  transition: all 0.3s;
  padding: 5px 25px 0 5px;
  font-size: 20px; 
  height: 100%;
  background: #1890ff;
}
</style>
