<template>
  <a-dropdown
    :getPopupContainer="
      (triggerNode) => {
        return triggerNode.parentNode || document.body;
      }
    "
    :overlayStyle="{ width: '300px' }"
    trigger="click"
    placement="bottomRight"
    v-model:visible="visible"
  >
    <span @click="() => (visible = !visible)" class="header-notice">
      <a-badge :offset="[4, 1]" count="12">
        <bell-outlined style="font-size: 16px; padding-top: 6px" />
      </a-badge>
    </span>

    <template #overlay>
      <div class="dropdown-win">
        <a-spin :spinning="loading">
          <a-tabs centered="true" :tabBarGutter="50">
            <a-tab-pane tab="通知" key="1">
              <a-list item-layout="horizontal">
                <a-list-item>
                  <a-avatar
                    style="background-color: white; margin-right: 15px"
                    slot="avatar"
                    src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"
                  />
                  <a-list-item-meta
                    title="您收到了 10 份新订单"
                    description="刚刚"
                  >
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item>
                  <a-avatar
                    style="background-color: white; margin-right: 15px"
                    slot="avatar"
                    src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"
                  />
                  <a-list-item-meta
                    title="餐饮系统已更新至最新版本"
                    description="刚刚"
                  >
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item>
                  <a-avatar
                    style="background-color: white; margin-right: 15px"
                    slot="avatar"
                    src="https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"
                  />
                  <a-list-item-meta
                    title="重要提醒：今日订座额满，恭喜！"
                    description="刚刚"
                  >
                  </a-list-item-meta>
                </a-list-item>
                <template #footer>
                  <div class="footer-bottom-bar">
                    <div>清空</div>
                    <div style="border-left: 1px solid #f0f0f0">更多</div>
                  </div>
                </template>
              </a-list>
            </a-tab-pane>
            <a-tab-pane tab="消息" key="2"><a-empty :image="simpleImage" /></a-tab-pane>
            <a-tab-pane tab="待办" key="3"><a-empty :image="simpleImage" /></a-tab-pane>
          </a-tabs>
        </a-spin>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { Empty } from 'ant-design-vue';
import { BellOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'HeaderNotice',
    components: {
        BellOutlined
    },
    setup() {
    // 消息通知下拉显示状态
        const visible = ref(false)
        const loading = ref(true)

        setTimeout(() => {
            loading.value = false
        }, 2000)

        return {
            loading,
            visible,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
        }
    }
})
</script>

<style lang="scss">
.ant-list-item {
  padding: 10px 15px;
  &:hover {
    color: #1890ff;
  }
}
.ant-list {
  .ant-list-footer {
    padding: 0px;
  }
}
.dropdown-win {
  // position: relative;
  // margin: 0;
  // padding: 10px 10px 0;
  // text-align: left;
  // list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  // outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.footer-bottom-bar {
  height: 46px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  transition: all 0.3s;

  div {
    display: inline-block;
    width: 50%;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}

.header-notice {
  display: inline-block;
  transition: all 0.3s;
  padding: 0 30px 0 5px;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }

  span {
    vertical-align: middle;
  }
}
</style>
