<template>
<a-sub-menu :key="menuInfo.name">
    <template v-if="menuInfo.meta.icon" #icon><component :is="menuInfo.meta.icon" /></template>
    <template #title>{{ menuInfo.title }}</template>
    <template v-for="item in menuInfo.children">
        <template v-if="item.children.length > 0">
            <sub-menu :menu-info="item" :key="item.name" />
        </template>
        <template v-else>
            <a-menu-item :title="item.meta.title" :key="item.name">
                <template v-if="item.meta.icon" #icon><component :is="item.meta.icon" /></template>
                {{ item.meta.title }}
            </a-menu-item>
        </template>
    </template>
</a-sub-menu>
</template>

<script>
import { defineComponent } from "vue";
import * as icons from "@ant-design/icons-vue";
// import { InfoCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
    name: "SubMenu",
    components: {
        ...icons
    },
    props: {
        menuInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {

        return {
            ...icons
        }
    }
});
</script>