import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/Layout'
import { HomeOutlined } from '@ant-design/icons-vue'

const routes = [
    { // 后台主页
        path: '/',
        name: 'home',
        redirect: '/home',
        component: Layout,
        meta: {
            icon: HomeOutlined,
            title: '首页'
        },
        children: [
            { // 后台主页
                path: 'home',
                name: 'home',
                component: () => import('@/views/Home.vue'),
            },
        ]
    },
    { // 登录页
        path: '/login',
        name: 'login',
        // component: () => import('../views/Login.vue')
        component: () => import('../views/user/Login.vue'),
        meta: {
            title: '登录'
        },
    },
    // { // 登录页
    //     path: '/user/login',
    //     name: 'login1',
    //     component: () => import('../views/user/Login.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // 设置页面标题
    if(to.meta.title){
        document.title = "抓客点单Saas版 - " + to.meta.title;
    }

    if (to.path !== '/login' && !sessionStorage.getItem('token')) {
        next('/login')
    }
    next()
})

export default router
