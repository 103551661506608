<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN"; // 引入antd中文包
import { defineComponent, nextTick, provide, ref } from "vue";
import moment from "moment"; // 引入moment
moment.locale("zh-cn"); // 配置moment中文环境
export default defineComponent({
  name: "App",
  components: {},
  data() {
    return {
      locale: zhCN, // 传值给a-config-provider组件
      date: "",
    };
  },
});
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  // font-size: 14px;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
// body {
//     margin: 0;
//     color: rgba(0,0,0,.65);
//     font-size: 14px;
//     font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
//     font-variant: tabular-nums;
//     line-height: 1.5;
//     background-color: #fff;
//     -webkit-font-feature-settings: "tnum";
//     font-feature-settings: "tnum";
// }
</style>
