const reconnectInterval = 3000

//初始化webScoket
function createWebSocket(url, onMessageCallback, onErrorCallback) {
    var ws = null
    var reconnectWebSocket = ()=>{
        setTimeout(() => {
            console.log('尝试重新连接WebSocket...');
            createWebSocket(url, onMessageCallback, onErrorCallback)
        }, reconnectInterval);
    }

    try {
        ws = new WebSocket(url);

        ws.onmessage = function (e) {
            console.log('收到的消息:', e.data);
            if (typeof onMessageCallback === 'function') {
                onMessageCallback(ws, e.data);

                // ws.send(JSON.stringify({'state':'ok'}));
            }
        };

        ws.onclose = function (e) {
            console.log("WebSocket关闭 (" + e.code + ")");
            reconnectWebSocket()
        };

        ws.onopen = function () {
            console.log("WebSocket连接成功");
        };
        
        //连接发生错误的回调方法
        ws.onerror = function (error) {
            console.log("WebSocket连接发生错误");
            if (typeof onErrorCallback === 'function') {
                onErrorCallback(error);
            }
        };
    } catch (error) {
        console.log('ws', error)
    }
    return ws
}

// webScoket重连
// function reconnectWebSocket() {
//     setTimeout(() => {
//         console.log('尝试重新连接WebSocket...');
//         initWebSocket()
//     }, reconnectInterval);
// }

// // 实际调用的方法
// function sendSock(agentData, callback) {
//     global_callback = callback;
//     if (ws.readyState === ws.OPEN) {
//         //若是ws开启状态
//         websocketsend(agentData);
//     } else if (ws.readyState === ws.CONNECTING) {
//         // 若是 正在开启状态，则等待1s后重新调用
//         setTimeout(function () {
//             sendSock(agentData, callback);
//         }, 1000);
//     } else {
//         // 若未开启 ，则等待1s后重新调用
//         setTimeout(function () {
//             sendSock(agentData, callback);
//         }, 1000);
//     }
// }

// //数据发送
// function websocketsend(agentData) {
//     ws.send(JSON.stringify(agentData));
// }

// initWebSocket();

export  {
    createWebSocket
};
