<template>
  <a-dropdown v-if="params.currentUser.username" placement="bottomRight">
    <span class="account-avatar">
      <!--<a-avatar size="small" :src="params.currentUser.avatar" class="avatar" />-->
      <a-avatar size="small" shape="square" class="avatar" style="background-color: rgba(24, 144, 255, 0.8);">
        <template #icon>
          <UserOutlined />
        </template>
      </a-avatar>
      <span class="username">{{ params.currentUser.username }}</span>
      <!-- <span class="role">{{ params.currentUser.role }}</span> -->
    </span>
    <template v-slot:overlay>
      <a-menu :selected-keys="[]">
        <a-menu-item v-if="params.menu" key="shop" @click="shopInfo">
          <ShopOutlined />
          商户信息
        </a-menu-item>
        <a-menu-item v-if="params.menu" key="settings" @click="handleToSettings">
          <setting-outlined />
          个人设置
        </a-menu-item>
        <a-menu-item v-if="params.menu" key="layout" @click="themeChange">
          <layout-outlined />
          主题切换
        </a-menu-item>
        <a-menu-divider v-if="params.menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <logout-outlined />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
  <!-- <span  class="role">{{ params.currentUser.role }}</span> -->
</template>

<script>
import { defineComponent, h } from "vue";
import { Modal, notification, Button } from "ant-design-vue";
import router from "@/router";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  ShopOutlined,
  LayoutOutlined
} from "@ant-design/icons-vue";
import axios from "axios";

export default defineComponent({
  name: "AvatarDropdown",
  components: {
    ShopOutlined,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    LayoutOutlined
  },
  props: {
    // currentUser: {
    //   type: Object,
    //   default: () => null,
    // },
    // menu: {
    //   type: Boolean,
    //   default: true,
    // },
    params: {
      type: Object,
      default: () => ({
        currentUser: {
          username: '',
          avatar: ''
        },
        themeWinState: false
      }),
    }
  },
  setup(props, { emit }) {
    const shopInfo = () => {
      // Modal.info({
      //   title: '商户信息',
      //   content: h('div', {}, [
      //     h('p', props.params.currentUser.mid),
      //     h('p', props.params.currentUser.expiry_time),
      //   ]),
      //   onOk() {
      //     console.log('ok');
      //   },
      // });
      const key = `open${Date.now()}`;
      notification.open({
        // icon: () => h(ShopOutlined, { style: 'color: #108ee9', fontSize: '12px'}),
        message: '商户信息',
        description:h('div', {}, [
          h('ul', { style: 'list-style-type: none; padding: 0;' }, [
            h('li', { style: 'margin-bottom: 5px;' }, [
              h('span', { style: 'font-weight: bold;' }, '商户ID：'),
              h('span', {}, props.params.currentUser.mid)
            ]),
            h('li', { style: 'margin-bottom: 5px;' }, [
              h('span', { style: 'font-weight: bold;' }, '有效期：'),
              h('span', {}, props.params.currentUser.expiry_time)
            ])
          ])
        ]),
        btn: () => h(Button, {
          type: 'primary',
          size: 'small',
          onClick: () => notification.close(key),
        }, {
          default: () => '确定',
        }),
        key,
        duration: 0,
      });

    };

    const handleToSettings = () => {
      this.$router.push({ path: "/account/settings" });
    };

    // 向父子组件传值,
    const themeChange = ()=>{
      emit('themeChange', true);
    }

    const handleLogout = (e) => {
      Modal.confirm({
        title: "信息",
        content: "您确定要退出吗？",
        onOk: () => {
          axios
            .get("/admin/logout")
            .then((resp) => {
              sessionStorage.clear();
              router.push({ path: "/login" });
            })
            .catch((resp) => {
              message.error("网络请求超时！");
            });
        },
        onCancel() {},
      });
    };
    return {
      shopInfo,
      handleToSettings,
      handleLogout,
      themeChange
    };
  },
});
</script>

<style lang="scss" scoped>
.account-avatar {
  display: inline-block;
  padding: 0 12px;
  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
  .avatar {
    margin-right: 8px;
  }
  .username {
    color: rgba(0, 0, 0, 0.65);
  }

  .role {
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
}
// .ant-dropdown-menu {
//   :deep(.action) {
//     margin-right: 8px;
//   }
//   :deep(.ant-dropdown-menu-item) {
//     min-width: 160px;
//   }
// }
</style>
